import React from 'react';
import { Button, Icon, Radio } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import _ from 'lodash';
import StateHelper from '~/utils/StateHelper';

import { useTranslation } from 'react-i18next';
import HitCounter from '~/components/index/HitCounter';
import Menu from '~/components/index/Menu';
import useLocation from '~/hooks/useLocation';
import useWindowDimensions from '~/hooks/useWindowDimensions';
import SelectRegionModal from '~/components/index/SelectRegionModal';
import { toLanguageText, toCountryText } from '~/utils/LocationHelper';
import { unstable_scheduleCallback, unstable_IdlePriority } from 'scheduler';

export default () => {
  const { t, i18n } = useTranslation();
  const { country, setCountry, state, setState } = useLocation();
  const [regionVisible, setRegionVisible] = React.useState(false);
  const [language, setLanguage] = React.useState(i18n.language);
  const { width: windowWidth } = useWindowDimensions();
  const isMobile = windowWidth <= 576;

  React.useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  React.useEffect(() => {
    window.addEventListener('load', () => {
      unstable_scheduleCallback(unstable_IdlePriority, () => {
        if (!localStorage.getItem('visited')) {
          setRegionVisible(true);
          localStorage.setItem('visited', 'visited');
        }
      });
    });
  }, []);

  React.useEffect(() => {
    const handleShowRegionModal = () => setRegionVisible(true);
    window.addEventListener('show-region-modal', handleShowRegionModal);
    return () => {
      window.removeEventListener('show-region-modal', handleShowRegionModal);
    };
  }, []);

  const toggleLanguage = lang => {
    i18n.changeLanguage(lang);
    if (_.startsWith(window.location.pathname, '/en/') || _.startsWith(window.location.pathname, '/zh/')) {
      window.history.replaceState(null, null, `/${lang}/${window.location.pathname.substring(4)}`);
    } else {
      window.history.replaceState(null, null, `/${lang}`);
    }
  };

  const lang = i18n.language;

  return (
    <>
      <header>
        <div className="bg" />
        <a href="https://www.1point3acres.com/" target="_blank" style={{ color: 'white', cursor: 'hand' }}>
          <img src="/images/logo-white.png" width={25} />
          <span>{t('header.1p3a')}</span>
        </a>
        <div className="visit-button">
          <HitCounter />
          <a href={`/${lang}/donate`} style={{ marginLeft: 5, marginRight: 5 }}>
            <Button size={isMobile ? 'small' : 'medium'} className="donate-button">
              Donate
            </Button>
          </a>
        </div>
        <h1>
          <a href={`/${lang}`} style={{ color: 'white' }}>
            <small>{t('header.ncov')}</small>
            <br />
            {process.browser && <span className={lang === 'zh' ? 'subtitle-zh' : 'subtitle'}>{t('header.title')}</span>}
          </a>
          {!_.isEmpty(state) && (
            <Radio.Group
              size="small"
              buttonStyle="solid"
              value={_.isEmpty(state) ? 'country' : 'state'}
              onChange={e => {
                setState(e.target.value === 'country' ? '' : state);
              }}
              style={{ verticalAlign: 'middle', wordWrap: 'normal' }}>
              <Radio.Button value="country">{t('header.national_view')}</Radio.Button>
              <Radio.Button value="state">
                {_.get(StateHelper.getStateStatsByShortName(state), lang === 'zh' ? 'name' : 'provinceName')}
                {t('header.state_view')}
              </Radio.Button>
            </Radio.Group>
          )}
          <span style={{ lineHeight: '30px' }}>
            {process.browser && isMobile ? (
              <Button size="small" ghost onClick={() => setRegionVisible(true)}>
                <Icon type="global" /> Language/切换语言&nbsp;
              </Button>
            ) : (
              <Radio.Group
                className="lang-radio-group"
                size="small"
                value={language}
                buttonStyle="solid"
                onChange={e => toggleLanguage(e.target.value)}
                style={{ marginTop: 10 }}>
                <Radio.Button value="en">English</Radio.Button>
                <Radio.Button value="zh">中文版</Radio.Button>
                <Radio.Button value="fr">Français</Radio.Button>
                <Radio.Button value="jp">日本語</Radio.Button>
                <Radio.Button value="es">español</Radio.Button>
              </Radio.Group>
            )}
            &nbsp;
            <Button size="small" ghost onClick={() => setRegionVisible(true)}>
              <Icon type="environment" /> {toCountryText(country, lang)} <CaretDownOutlined />
            </Button>
          </span>
          <br />
        </h1>
        <div className="header-menu">
          <Menu />
        </div>
      </header>
      <SelectRegionModal visible={regionVisible} setRegionVisible={setRegionVisible} />
      <style jsx>{`
        header {
          position: relative;
          color: #fff;
          padding: 0.25rem 1rem 0.4rem 1rem;
          margin-bottom: 10px;

          :global(.ant-switch-checked) {
            background: #497b89;
          }
        }
        header :global(button) {
          color: #fff;
          margin-right: 10px;
          margin-left: 10px;
          vertical-align: middle;
        }
        .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url(/images/feiyan.jpeg);
          background-position: center;
          background-size: 100%;
          z-index: -1;
          filter: brightness(0.5);
        }
        .visit-button {
          display: inline-block;
          position: absolute;
          top: 8px;
          right: 0;
        }
        :global(.donate-button) {
          background: #ff3860 !important;
        }
        :global(.donate-button:hover) {
          color: #fff !important;
          border-color: #fff !important;
        }
        span {
          font-size: 16px;
          font-weight: bold;
          vertical-align: bottom;
          padding-left: 3;
        }
        h1 {
          color: white;
          fontweight: bold;
          margin-top: 0;
          margin-bottom: 0;
          line-height: 2rem;
        }
        @media screen and (max-width: 576px) {
          h1 {
            line-height: 1.5rem;
            letter-spacing: -0.5px;
          }
        }
        small {
          font-weight: bolder;
        }
        .subtitle {
          font-size: 1rem;
          line-height: 2rem;
        }
        .subtitle-zh {
          font-size: 1.5rem;
          line-height: 1.8rem;
        }
        @media screen and (max-width: 576px) {
          small {
            font-size: 16px;
          }
          .subtitle {
            font-size: 0.8rem;
            line-height: 1rem;
          }
          .subtitle-zh {
            font-size: 1.5rem;
            line-height: 1.7rem;
            display: block;
          }
        }
        :global(.lang-radio-group) {
          margin-left: 20px;
        }
        @media screen and (max-width: 576px) {
          header :global(button) {
            margin-left: 0px;
            margin-right: 0px;
            padding-left: 5px;
            padding-right: 3px;
          }
          :global(.lang-radio-group) {
            margin-left: 0px;
          }
        }
        .header-menu {
          margin-left: -16px;
          margin-right: -15px;
          margin-bottom: -6px;
        }
        .state {
          font-size: 14px;
          display: inline;
        }
      `}</style>
    </>
  );
};
