import React from 'react';
import { useTranslation } from 'react-i18next';

import MapAdapter from '~/components/index/MapAdapter';
import Stat from '~/components/share/Stat';
import all from '~/data/overall';
import FooterEn from '~/components/index/share/FooterEn';
import FooterZh from '~/components/index/share/FooterZh';
import LineChart from '~/components/index/LineChart';
// import TotalBarChart from '~/components/Test/TotalBarChart';
import useLocation from '~/hooks/useLocation';

export default () => {
  const { t, i18n } = useTranslation();
  const { country } = useLocation();
  if (!process.browser) return null;
  return (
    <>
      <article className="share-card">
        <h1># {t('share.h1')} #</h1>
        <div className="content">
          <Stat {...all} modifyTime={all.modifyTime} />
          <MapAdapter share />
        </div>
        {i18n.language === 'zh' ? <FooterZh /> : <FooterEn />}
      </article>
      <article className="share-card-extended">
        <h1># {t('share.h1')} #</h1>
        <div className="content">
          <Stat {...all} modifyTime={all.modifyTime} />
          <MapAdapter share />
        </div>
        <br />
        <div className="content">
          <LineChart view="confirmed" share />
        </div>
        <br />
        <div className="content">
          <LineChart view="deaths" share />
        </div>
        {/* <br />
        {country === 'us' && (
          <div className="content">
            <TotalBarChart share />
          </div>
        )} */}
        {i18n.language === 'zh' ? <FooterZh /> : <FooterEn />}
      </article>
      <style jsx>{`
        article {
          background: #497b89;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px;
          width: 375px;
          position: fixed;
          top: -9999px;
          left: 0px;
          z-index: 100;
        }
        h1 {
          color: #fff;
        }
        .content {
          background: #fff;
          @media screen and (prefers-color-scheme: dark) {
            background-color: var(--dark-lighter-bg-color);
          }
          width: 100%;
          border-radius: 6px;
          padding: 10px 5px;
        }
        @media screen and (min-width: 576px) {
          article {
            width: 375px;
          }
        }
        @media screen and (max-width: 576px) {
          .share-image {
            width: 100vw;
          }
        }
      `}</style>
    </>
  );
};
