import React from 'react';
import useWindowDimensions from '~/hooks/useWindowDimensions';

export default ({ width = '100%', height = '100px', slot = '2709804803' }) => {
  const adContainer = React.useRef();
  const { width: windowWidth } = useWindowDimensions();
  const isMobile = windowWidth <= 576;

  React.useEffect(() => {
    if (adContainer && adContainer.current.clientWidth >= 120) {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (err) {}
    }
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <ins
        ref={adContainer}
        className="adsbygoogle"
        style={{
          display: 'inline-block',
          width,
          height,
        }}
        data-ad-client="ca-pub-4132348358369185"
        data-ad-slot={slot}
      />
    </div>
  );
};
