import React from 'react';
import { useRouter } from 'next/router';
import { Button, Icon, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ShareImage from '~/components/index/share/ShareImage';
import useWindowDimensions from '~/hooks/useWindowDimensions';

export default () => {
  const [showModal, setShowModal] = React.useState(false);
  const [showWechat1P3A, setShowWechat1P3A] = React.useState(false);
  const { t, i18n } = useTranslation();
  const [url, setUrl] = React.useState(null);
  const [extendedUrl, setExtendedUrl] = React.useState(null);
  const [showShareImage, setShowShareImage] = React.useState(false);
  const [showBookmark, setShowBookmark] = React.useState(false);
  const [showSpeedDial, setShowSpeedDial] = React.useState(false);
  const router = useRouter();
  const { width: windowWidth } = useWindowDimensions();
  const isMobile = windowWidth <= 576;

  const handleClickDonation = () => {
    const currentRoute = router.pathname;
    const newRoute = `/${i18n.language}/donate`;
    if (newRoute !== currentRoute) {
      window.open(newRoute, '_self');
    } else {
      window.scrollTo({
        top: 0,
      });
    }
  };

  const handleClickTwitter = () => {
    window.open('https://twitter.com/1p3adev', '_blank');
  };

  const handleShare = async () => {
    setShowShareImage(true);
    const html2canvas = await import('html2canvas');
    const canvas = await html2canvas.default(document.querySelector('.share-card'), {
      scrollY: 0,
      backgroundColor: 'transparent',
    });
    const url = canvas.toDataURL();
    setUrl(url);
    const canvasExtended = await html2canvas.default(document.querySelector('.share-card-extended'), {
      scrollY: 0,
      backgroundColor: 'transparent',
    });
    const extendedUrl = canvasExtended.toDataURL();
    setExtendedUrl(extendedUrl);
  };

  return (
    <>
      {showShareImage && <ShareImage url={url} extendedUrl={extendedUrl} onClose={() => setShowShareImage(false)} />}
      <Modal title="bookmark" visible={showBookmark} footer={null} onCancel={() => setShowBookmark(false)}>
        <iframe
          className="video"
          src={`https://www.youtube.com/embed/a2BaqAetHH4`}
          frameBorder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
      </Modal>
      <footer>
        <SpeedDial
          ariaLabel="SpeedDial"
          icon={<Icon type="menu" className="fab-icon" />}
          onClose={() => setShowSpeedDial(false)}
          onOpen={() => setShowSpeedDial(true)}
          open={showSpeedDial}
          direction="up"
          FabProps={{ size: isMobile ? 'medium' : 'large' }}>
          {[
            {
              icon: <Icon type="dollar" className="fab-icon" style={{ color: '#ff902f' }} />,
              name: (
                <div style={{ width: i18n.language === 'zh' ? 70 : 140, textAlign: 'right', fontWeight: 'bold' }}>
                  {t('footer.boba.button')}
                </div>
              ),
              onClick: handleClickDonation,
            },
            {
              icon: <Icon type="twitter" className="fab-icon" style={{ color: '#0c7abf' }} />,
              name: 'Twitter',
              onClick: handleClickTwitter,
            },
            ...(i18n.language === 'zh'
              ? [
                  {
                    icon: <Icon type="wechat" className="fab-icon" style={{ color: '#1aad19' }} />,
                    name: t('footer.wp.button'),
                    onClick: () => setShowWechat1P3A(true),
                  },
                ]
              : []),
            {
              icon: <Icon type="book" className="fab-icon" />,
              name: t('footer.bookmark.button'),
              onClick: () => setShowBookmark(true),
            },
            {
              icon: <Icon type="share-alt" className="fab-icon" />,
              name: t('footer.share.button'),
              onClick: handleShare,
            },
            ...(i18n.language === 'zh'
              ? [
                  {
                    icon: <Icon className="fab-icon" type="bell" />,
                    name: t('footer.subscribe.button'),
                    onClick: () => setShowModal(true),
                  },
                ]
              : []),
          ].map(action => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={action.onClick}
            />
          ))}
        </SpeedDial>
        {showModal && (
          <div className="modal-cover">
            <div className="header">
              <strong>{t('footer.subscribe.title')}</strong>
              <Button shape="circle" icon="close" onClick={() => setShowModal(false)} />
            </div>
            <h2>{t('footer.subscribe.download')}</h2>
            <div className="app-qr">
              <div>
                <h3>iOS</h3>
                <img className="app" src="/images/app-ios.png" />
                <div>
                  <Button
                    block
                    href="https://apps.apple.com/us/app/yi-mu-san-fen-de-liu-xue-she-qu/id1018241310"
                    target="_blank">
                    App Store
                  </Button>
                </div>
              </div>
              <div>
                <h3>Android</h3>
                <img className="app" src="/images/app-android.png" />
                <div>
                  <Button
                    block
                    href="https://play.google.com/store/apps/details?id=com.nemoleoliu.OnePointThreeAcres"
                    target="_blank">
                    Google Play
                  </Button>
                </div>
              </div>
              <div>
                <h3>微信公众号</h3>
                <img className="app" src="/images/app-wechat.jpg" />
              </div>
            </div>
            国内安卓用户请先关注微信公众号，然后回复 APP，获取百度网盘APK下载链接
            <p></p>
            <h2>{t('footer.subscribe.notify')}</h2>
            <p dangerouslySetInnerHTML={{ __html: t('footer.subscribe.text') }} />
            <img className="guide" src="/images/guide.png" />
          </div>
        )}
        {showWechat1P3A && (
          <div className="mp-cover" onClick={() => setShowWechat1P3A(false)}>
            <div className="mp-content">
              <div className="mp-pc">
                <div>
                  <p>请使用微信扫码</p>
                  <img src="/images/app-wechat.jpg" />
                </div>
              </div>
              <div className="mp-mobile">
                <div>
                  <p>微信用户请长按识别二维码</p>
                  <img src="/images/app-wechat.jpg" />
                </div>
              </div>
            </div>
          </div>
        )}
      </footer>
      <style jsx>{`
        footer {
          position: fixed;
          bottom: 115px;
          right: 25px;
          box-sizing: content-box;
          display: flex;
          justify-content: space-around;
          z-index: 200;
          padding-bottom: env(safe-area-inset-bottom);
          @media screen and (max-width: 576px) {
            right: 12px;
            bottom: 150px;
          }
        }
        :global(.fab-icon) {
          font-size: 20px;
        }
        .modal-cover {
          width: 100vw;
          height: 100vh;
          background-color: #fff;
          position: fixed;
          top: 0;
          left: 0;
          padding: 10px;
          overflow: scroll;
        }
        .modal-cover .header {
          font-size: 18px;
          margin-bottom: 1vh;
          display: flex;
          justify-content: center;
          position: relative;
        }
        .modal-cover .header :global(button) {
          position: absolute;
          top: 0px;
          right: 0px;
        }
        .modal-cover .guide {
          width: 80vw;
          display: block;
          margin: 0px auto;
        }
        .modal-cover .app {
          width: 15vh;
        }
        .mp-cover {
          width: 100vw;
          height: 100vh;
          background-color: rgba(0, 0, 0, 0.6);
          position: fixed;
          top: 0;
          left: 0;
        }
        .mp-content {
          color: #fff;
          font-size: 18px;
          text-align: center;
          padding-top: 200px;
        }
        .mp-pc {
          color: #fff;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        .mp-cover img {
          display: block;
          width: 206px;
          height: 222px;
          margin-left: auto;
          margin-right: auto;
        }
        .app-qr {
          display: flex;
          justify-content: space-around;
          margin-bottom: 3vh;
        }
        .app-qr h3 {
          padding-left: 5px;
        }
        .share-pc {
          color: #fff;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          text-align: center;
        }
        #update-notice {
          font-size: 14px;
        }

        @media screen and (max-width: 576px) {
          .share-pc {
            display: none;
          }
          .mp-pc {
            display: none;
          }
        }
        @media screen and (min-width: 576px) {
          .share-mobile {
            display: none;
          }
          .mp-mobile {
            display: none;
          }
          .guide {
            width: 40vw !important;
          }
        }
        .video {
          width: 100%;
          height: 50vh;
        }
      `}</style>
    </>
  );
};
