import React from 'react';
import { Modal, Select, Radio, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { getCountries, toLanguageText, toCountryText } from '~/utils/LocationHelper';
import useLocation from '~/hooks/useLocation';

const languages = ['en', 'zh', 'fr', 'jp', 'es'];

export default ({ visible, setRegionVisible }) => {
  const { t, i18n } = useTranslation();
  const { country, setCountry } = useLocation();
  const [value, setValue] = React.useState();
  const [language, setLanguage] = React.useState(i18n.language);

  const toggleLanguage = lang => {
    i18n.changeLanguage(lang);
    if (_.startsWith(window.location.pathname, '/en/') || _.startsWith(window.location.pathname, '/zh/')) {
      window.history.replaceState(null, null, `/${lang}/${window.location.pathname.substring(4)}`);
    } else {
      window.history.replaceState(null, null, `/${lang}`);
    }
  };

  const handleOk = () => {
    toggleLanguage(language);
    setCountry(value);
    setRegionVisible(false);
  };

  React.useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  React.useEffect(() => {
    setValue(country);
  }, [country]);

  return (
    <Modal visible={visible} onCancel={() => setRegionVisible(false)} onOk={handleOk} zIndex={1000001}>
      <div>
        <h4>Select Language(选择语言)</h4>
        <Radio.Group value={language} buttonStyle="solid" size="small" onChange={e => setLanguage(e.target.value)}>
          {languages.map(language => (
            <Radio.Button key={language} value={language}>
              {toLanguageText(language)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
      <br />
      <div>
        <h4>Select Region(选择地区)</h4>
        <div className="tags">
          Most viewed (热门国家/地区):
          <br />
          {['us', 'ca', 'China'].map(v => (
            <Tag key={v} onClick={() => setValue(v)} color={value === v ? '#1890ff' : null}>
              {toCountryText(v, language)}
            </Tag>
          ))}
        </div>
        Or select one from the list below (更多国家/地区):
        <Select
          showSearch
          style={{ width: '100%' }}
          dropdownStyle={{ zIndex: 1000001 }}
          onChange={v => setValue(v)}
          value={value}>
          {getCountries().map(country => (
            <Select.Option key={country} value={country}>
              {toCountryText(country, language)}
            </Select.Option>
          ))}
        </Select>
      </div>
      <style jsx>{`
        .tags {
          padding-bottom: 5px;
          :global(.ant-tag) {
            cursor: pointer;
          }
        }
      `}</style>
    </Modal>
  );
};
