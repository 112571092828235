import { Spin, Radio } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  LineShareButton,
  RedditShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  LineIcon,
  RedditIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';

const SHARE_URL = 'https://coronavirus.1point3acres.com/en';
const SHARE_ICON_SIZE = 46.5;
const TITLE = 'Coronavirus in US and Canada';

export default ({ url, extendedUrl, onClose }) => {
  const { t } = useTranslation();
  const [extended, setExtended] = React.useState(false);
  return (
    <>
      <section>
        <div className="cover" onClick={onClose} />
        <Spin spinning={!url} />
        {url && (
          <div>
            <img
              style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
              src={extended ? extendedUrl : url}
            />
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <Radio.Group
                value={extended}
                size="small"
                onChange={e => setExtended(e.target.value)}
                className="radio-og">
                <Radio.Button value={false}>{t('share.compact_mode')}</Radio.Button>
                <Radio.Button value={true}>{t('share.extended_mode')}</Radio.Button>
              </Radio.Group>
            </div>
            <p>{t('share.save_image')}</p>
            <div style={{ textAlign: 'center' }}>
              <TwitterShareButton url={SHARE_URL}>
                <TwitterIcon size={SHARE_ICON_SIZE} />
              </TwitterShareButton>
              <FacebookShareButton url={SHARE_URL}>
                <FacebookIcon size={SHARE_ICON_SIZE} />
              </FacebookShareButton>
              <LinkedinShareButton url={SHARE_URL}>
                <LinkedinIcon size={SHARE_ICON_SIZE} />
              </LinkedinShareButton>
              <RedditShareButton url={SHARE_URL}>
                <RedditIcon size={SHARE_ICON_SIZE} />
              </RedditShareButton>
              <WhatsappShareButton url={SHARE_URL}>
                <WhatsappIcon size={SHARE_ICON_SIZE} />
              </WhatsappShareButton>
              <LineShareButton url={SHARE_URL}>
                <LineIcon size={SHARE_ICON_SIZE} />
              </LineShareButton>
              <EmailShareButton url={SHARE_URL} title={TITLE}>
                <EmailIcon size={SHARE_ICON_SIZE} />
              </EmailShareButton>
            </div>
          </div>
        )}
      </section>
      <style jsx>{`
        section {
          display: flex;
          align-items: center;
          justify-content: center;
          position: fixed;
          width: 100vw;
          height: 100vh;
          top: 0px;
          left: 0px;
          z-index: 999;
        }
        .cover {
          width: 100vw;
          height: 100vh;
          background: red;
          position: fixed;
          z-index: -1;
          background-color: rgba(0, 0, 0, 0.6);
        }
        p {
          color: #fff;
          text-align: center;
          margin-top: 10px;
        }
        img {
          max-width: 70vw;
          max-height: 75vh;
        }
        @media screen and (min-width: 576px) {
          img {
            max-width: 375px;
            max-height: 75vh;
          }
        }
      `}</style>
    </>
  );
};
