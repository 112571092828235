import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import adsList from '~/data/amazon_ads';

export default () => {
  const { t, i18n } = useTranslation();
  const amaz = React.useRef();
  React.useEffect(() => {
    const scripts = [document.createElement('script'), document.createElement('script')];
    let parts = _.partition(adsList, item => {
      return item['top'] && item['top'] === '1';
    });
    const arrays = parts[0].concat(_.sampleSize(parts[1], 8 - parts[0].length));
    const ads = arrays.map(item => item.amazon_id).join();
    scripts[0].innerHTML = `
      amzn_assoc_placement = "adunit0";
      amzn_assoc_tracking_id = "1p3a-coronavirus-20";
      amzn_assoc_ad_mode = "manual";
      amzn_assoc_ad_type = "smart";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_linkid = "7b3e6049309e176321c37e414f3a538a";
      amzn_assoc_asins = "${ads}";
      amzn_assoc_search_bar = "false";
      amzn_assoc_title = "";
    `;
    scripts[1].src = '//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US';
    scripts.forEach(script => {
      amaz.current.appendChild(script);
    });
  }, []);
  return (
    <section ref={amaz} className="card" id="amzn">
      <h2>{t('nav.amzn')}</h2>
      <div id="amzn_assoc_ad_div_adunit0_0" />
    </section>
  );
};
